import * as types from '../mutation-types'
import _ from 'lodash'
import axios from '../../common/axios.js'
import moment from 'moment'
import { i18n as vi18n } from '../../main.js'

// initial state
const state = {
	env: 'DEV',
	device: null, //mobile, tablet, desktop
	page: 'search', //search, results
	content: 'result', //result, map, account, graph, favoris, search, download, surfaces
	monentreprise_subcontent: '',
	allfavoris_subcontent: '',
	class_impression: 'print-defaut', 	//AS--> Permet de définir une class pour l'impression et modifier les éléments imprimés
	// content: 'graph',
	modal: null, //intro, save_search ,contact ,alert_subscription ,advice ,advice_stars ,connection ,password ,phone_code ,phone_validation ,signin 
	modal_standby: null, //AS--> Permet de stocker la modale qui doit s'ouvrir après celle en cours
	modal_params: {},
	modal_content: null, //code d'une page à charger
	mode_affichage: null,
	selected_companies: [],
	menu_open: false,
	show_filters: false,
	show_filter_index: 1, //1:params, 2:acteurs, 3:déchets, 4:caractéristique, 5:variables, 6:secteurs
	show_favoris_menus: true,
	map_open: true,
	map_height: 200,
	plage: { min: 0, max: 50 },
	statuses: [],
	dechets: [],
	dechets_liste: [],
	familles: [],
	secteurs: [],
	code_naces: [],
	tags: [],
	competences: [],
	marches: [],
	edition_matiere_fo: [],
	filtres: [],
	matiere: null, //AS--> Pour afficher la modale de présentation du détail d'une matière
	langue: 'fre',
	langues_ids: {
		'fre' : 1,
		'eng' : 2,
		'esp' : 3
	},
	images_ip: {},
	sourcing: {},
	dechets_columns: [],
	custom_filters: {},
	variables_filters: {},
	unites: {},
	last_contact_id_phoned: null,
	loading: false,
	loading_map: false,
	loading_first: false,
	export_id: false,
	export_type: '',
	map_size: 75,
	_get: {},
	full_screen: false,
	capacites: {},
	layers: [],
	layers_loaded: [],
	layers_zoom_alert: [],
	layers_data: {},
	layers_opened: [],
	a: 'vzzRffk6GcBxLjs8Mu3EwdBEDpwwQVnu',
	b: '1978544!fr854r@qmlksj*qa',
	layers_legendes_opened: [],
	layers_opacities: {},
	map_layer_opened: null,
	layer_entreprises: {
		enable: 0,
		open: true
	},
	layer_heatmap: {
		enable: 0,
		open: false
	},
	layer_heatmap_pvrmeta: {
		enable: 0,
		open: false
	},
	surface_id: null,
	map_zone_google: {
		bounds : 	null,
		zoom: 		null,
		polygon: 	null,
		area: 		null
	},
	map_state: {
		zoom: 		null,
		center: 	null
	},

	recherche: null,
	recherche_id: null,
	recherche_edition: false,
	recherche_supprIds: [],

	streetMapIsVisible: false,
	upload_image: null, 		//AS--> Stock une instance d'image uploadée gérée par une modale
	save_user_loading: false, 	//AS--> Se met à true pendant l'enregistrement d'un utilisateur et le rechargement de l'app si besoin
	barre_recherche_active: null,  //AS--> Barre de recherche actuellement affichée
	map_adresse: null, 				//AS--> Envoyer dans cette variable une adresse retournée par GooglePlace pour placer un marker et centrer la carte
	loading_layer: 0,

	map_tools_distance: false,	//AS--> Activation du l'outil de mesure des distances sur la map
	map_tools_surface: false,	//AS--> Activation du l'outil de mesure des surfaces sur la map
	map_itineraire_result: null, 		//AS--> Stock un itinéraire calculé
	map_itineraire_geojson: null, 		//AS--> Stock un itinéraire à afficher en geojson
	map_isoline_result: null, 		//AS--> Stock un isoline calculé
	map_isoline_geojson: null, 		//AS--> Stock un isoline à afficher en geojson

	modale_gauche: null, 		//AS--> Variable pour indiquer le nom d'un composant à afficher dans l'emplacement de modale gauche

	map_get_coords: false,
	map_coords_pointed: null,
	map_pin_pointed: null,

	screenPdfOn: false,

	first_data_loaded: false,

	menu_sous_menu_timestamp_opened: 0,

	zoomAuto: null, 		//AS--> A faire varier pour déclencher un zoom auto de la map sur la zone intéressante
	zoomAuto_last: null, 	//AS--> Mémorise le dernier zoom auto demandé et ne redéclenche pas tant qu'il n'y a pas de changement

	map_custom_info_window: null, 	//AS--> Permet de stocker un contenu pour une info window custom sur la map
	map_custom_info_window_multiple: [], 	//AS--> Permet de stocker des contenus pour info window custom sur la map

}

// getters
const getters = {
	isDev: state => {
		return state.env == 'DEV'
	},
	getSourcingCode: state => {
		return state.sourcing.url_sourcing
	},
	fonctionnement: state => {
		return state.sourcing.fonctionnement
	},
	langCode: state => { //AS--> Retourne le code de la langue utilisée par le système pour l'affichage des données en fonction de la langue par défaut de la vue et de l'utilisateur
		return state.langue
	},
	langId: state => { //AS--> Retourne l'id de la langue utilisée par le système pour l'affichage des données en fonction de la langue par défaut de la vue et de l'utilisateur
		return state.langues_ids[state.langue]
	},
	getSecteurs: state => {
		return state.secteurs
	},
	getSecteursIds: state => {
		let secteurs_ids = []

		_.each(state.secteurs, function(secteur){
			if (secteur.last_childs_ids.length)  secteurs_ids = _.concat(secteurs_ids, secteur.last_childs_ids)
		})

		return secteurs_ids
	},
	getDechets: state => {
		return state.dechets
	},
	getFamillesIds: state => {
		let familles_ids = []

		_.each(state.sourcing.familles, function(famille){
			if (famille.parent_id != 0) {
				familles_ids.push(famille.id)
			}
		})

		return familles_ids
	},
	getFamillesParams: state => {
		let familles_params = JSON.parse(state.sourcing.familles_params);
		return familles_params;
	},
	getFamillesOuvertesParamsIds: (state, getters) => {
		let familles_params = JSON.parse(state.sourcing.familles_params);
		let familles_ids = [];
		_.forEach(familles_params, function(v,f_id){
			if(getters.getFamillesIds.includes(parseInt(f_id)) && v.ouvert && v.ouvert == "1") familles_ids.push(parseInt(f_id));
		});
		return familles_ids;
	},
	getCapacitesIds: state => {
		let capacites_ids = [];
		state.sourcing.familles.forEach(function(famille){
			if(famille.capacites_ids) capacites_ids = capacites_ids.concat(famille.capacites_ids);
		})
		return capacites_ids;
	},
	getCapaciteFonctionnement: (state, getters, rootState) => (capacite_id) => {
		return state.sourcing.capacites && state.sourcing.capacites[capacite_id] && state.sourcing.capacites[capacite_id].fonctionnement ? state.sourcing.capacites[capacite_id].fonctionnement : null
	},
	getColonnes: state => {
		return state.sourcing.colonnes ? state.sourcing.colonnes.split(',') : [];
	},
	getMenusColonneVerte: state => {
		// return state.sourcing.menus_colonne_verte ? _.filter(_.replace(state.sourcing.menus_colonne_verte, 'secteurs', '').split(',')) : [];
		return state.sourcing.menus_colonne_verte ? _.filter(state.sourcing.menus_colonne_verte.split(',')) : [];
	},
	getMenusMonentreprise: state => {
		return state.sourcing.menus_monentreprise ? _.filter(state.sourcing.menus_monentreprise.split(',')) : [];
	},
	getUiFiltres: state => {
		return !state.filtres || !state.filtres.length ? null : state.filtres;
	},
	getFicheInfosEditions: state => {
		return state.sourcing.fiche_infos_editions ? _.filter(state.sourcing.fiche_infos_editions.split(',')) : [];
	},
	getFichePictosHautDroite: state => {
		return state.sourcing.fiche_pictos_haut_droite ? state.sourcing.fiche_pictos_haut_droite.split(',') : [];
	},
	getFicheAdministratif: state => {
		return state.sourcing.fiche_administratif ? state.sourcing.fiche_administratif.split(',') : [];
	},
	getFicheInfos: state => {
		return state.sourcing.fiche_infos ? state.sourcing.fiche_infos.split(',') : [];
	},
	getFicheInfosPlus: state => {
		return state.sourcing.fiche_infos_plus ? state.sourcing.fiche_infos_plus.split(',') : [];
	},
	getLanguesCodes: state => {
		if(state.sourcing.langues_codes && state.sourcing.langues_codes.length != ''){
			return state.sourcing.langues_codes.split(',');
		}
		return [];
	},
	getStatuses: state => {
		let statuses = state.statuses
		statuses = _.orderBy(statuses, ['order'], ['asc'])
		return statuses
	},
	//AS--> Si la case Export CSV est cochée en BO
	canExportCSV: (state, getters, rootState, rootGetters) => {
		return rootGetters.userCanExport && state.sourcing.exports > 0
	},
	//AS--> Si la case Export CRM est cochée en BO
	canExportCRM: (state, getters, rootState, rootGetters) => {
		return rootGetters.userCanExport && state.sourcing.exports_crm > 0
	},
	//AS--> Si la case Export Matières est cochée en BO
	canExportMatieres: (state, getters, rootState, rootGetters) => {
		return state.sourcing.exports_matieres > 0
	},
	//AS--> Si une des cases d'export est cochée en BO
	canExport: (state, getters, rootState, rootGetters) => {
		return getters.canExportCSV || getters.canExportCRM || getters.canExportMatieres
	},
	//AS--> Si une des cases d'export est cochée en BO et qu'il s'agit d'une vue de type déchet
	canExportData: (state, getters, rootState, rootGetters) => {
		//AS--> Ne fonctionne plus que pour les vues en fonctionnement déchets à la demande de Ksenya #683
		return rootGetters.userCanExport && rootGetters.fonctionnement == 'dechets' && (state.sourcing.exports > 0 || state.sourcing.exports_crm > 0 || state._get.exports)
	},
	canUseIp: state => {
		return state.sourcing.presence_ip > 0;
	},
	canIntro: (state, getters, rootState) => {
		//AS--> Intro si le contenu de modale est défini et que menus_monetreprise est vide. Sinon, s'affichera sur la page de bienvenue de mon entreprise
		return rootState.user.user.nom && ((state.sourcing.modale && state.sourcing.modale.length > 10 || state.sourcing.video_intro) && !state.sourcing.menus_monentreprise);
	},
	canIntroMenu: state => {
		//AS--> Retiré au profit du Centre d'aide
		return false
		//AS--> Ne tient pas compte de la page mon entreprise si c'est pour le menu
		return state.sourcing.modale && state.sourcing.modale.length > 10 || state.sourcing.video_intro;
	},
	canFavoris: state => {
		return state.sourcing.gestion_favoris;
	},
	canSurfaces: state => {
		return state.sourcing.menu_surfaces;
	},
	canMenuSecondaire: state => {
		return state.sourcing.gestion_favoris || !state.sourcing.mono_compte;
	},
	canGestionCompte: state => {
		return !state.sourcing.mono_compte;
	},
	canMesureDistance: state => {
		return state.sourcing.map_tools_distance;
	},
	canMesureSurface: state => {
		return state.sourcing.map_tools_surface;
	},
	canParcelleFavoris: state => {
		return state.sourcing.parcelles_clicables
	},
	hasCustomSurface: state => {
		return state.sourcing.map_tools_surface_custom_ratio > 0;
	},
	canMenuTelechargements: (state, getters, rootState, rootGetters) => {
		if(state.sourcing.menu_telechargements){
			return ['admin_vuet', 'inex'].includes(rootState.user.user.type_membre_equipe)
		}
		return false
	},
	hasGeoCenter: state => {
		return state.sourcing.position_zone != '';
	},
	canShowAireLimit: state => {
		return state.sourcing.show_aire_limit;
	},
	//AS--> Vérifie si les colonnes de déchets peuvent s'afficher en fonction des familles sélectionnées. Il faut qu'il y ait au moins 1 famille qui accepte les filtre_dechets
	canShowColonneDechets: (state, getters, rootState) => {
		let show = false;
		state.sourcing.familles.forEach(function(famille){
			if(rootState.filters.filters.familles_ids.includes(famille.id) && famille.filtre_dechets == 1) show = true;
		})
		return show;
	},
	//AS--> Vérifie si une colonne de capacité peuvent s'afficher en fonction de la présence de résultats pour cette capacité dans l'échantillon de recherche et en fonction de la présence de cette capacité dans les familles sélectionnées
	canShowColonneCapacite: (state, getters, rootState) => (capacite_id) => {
		let show = false;
		state.sourcing.familles.forEach(function(famille){
			//AS--> Si présent dans une famille sélectionnée
			if(rootState.filters.filters.familles_ids.includes(famille.id) && famille.capacites_ids.includes(capacite_id)) 
				//AS--> Si existant dans l'échantillon retourné
				show = rootState.filters.capacites_totaux[capacite_id] && rootState.filters.capacites_totaux[capacite_id]['nbr'];
		})
		return show;
	},
	//AS--> Vérifie si une colonne de variable peut s'afficher. Elle s'affiche si au moins une donnée est présente dans cette colonne
	canShowColonneVariable: (state, getters, rootState) => (variable) => {
		return rootState.filters.variables_totaux[variable.field] && rootState.filters.variables_totaux[variable.field].connus > 0
	},
	canShowChiffresCles: (state, getters, rootState) => {
		return state.sourcing.fiche_chiffres_cles
	},
	canUseStatuses: state => {
		return state.sourcing.colonnes.split(',').includes('statut')
	},
	getDechetField: state => (id, field) => {
		let dechet = null;
		state.dechets_liste.forEach(function(d, i){
			if(d.id == id) dechet = d;
		})
		return dechet && dechet[field] ? dechet[field] : null;
	},
	getLayers: (state, getters) => (params, famille_id) => {
		let layers = [];
		if(!params){
			state.layers.forEach(function(ui_layer){
				if((!famille_id && !ui_layer.famille) || (famille_id && ui_layer.famille == famille_id)) layers.push(ui_layer);
			})
		}else{
			if(params.list && params.list.length){
				params.list.forEach(function(layer, i1){
					if(layer.list && layer.list.length){
						layer = getters.getLayers(layer, famille_id);
					}else if(layer.id){
						let ui_layer = null;
						state.layers.forEach(function(_ui_layer){
							// if(_ui_layer.layer == layer.id && ((!famille_id && !_ui_layer.famille) || (famille_id && _ui_layer.famille == famille_id))){
							if(_ui_layer.layer == layer.id){
								layer.nom = layer.title;
								ui_layer = layer;
								ui_layer = Object.assign(_ui_layer, layer);
							}
						})
						params.list[i1] = ui_layer ? ui_layer : null;
					}
				})
			}
			layers = params;
		}
		return layers;
	},
	//AS--> Fonction qui vise à réduire une arbo inutile. Si une entrée de l'arbo n'a qu'un seul enfant et que cet enfant porte le même nom, on remplace cette entrée par son enfant
	reduitArboDechets: (state, getters) => (arbo) => {
		_.forEach(arbo, function(value, index){
			if(value.childs){
				if(Object.keys(value.childs).length == 1){
					let child = Object.values(value.childs)[0];
					if(!child.childs && child.data.titre == value.data.titre){
						arbo[index] = child;
					}else{
						value = getters.reduitArboDechets(value)
					}
				}else{
					value.childs = getters.reduitArboDechets(value.childs)
				}
			}
		})
		return arbo
	},
	//AS--> Fonction qui vise à réduire une arbo inutile. Si une entrée de l'arbo n'a qu'un seul enfant et que cet enfant porte le même nom, on remplace cette entrée par son enfant
	definitionsArboDechets: (state, getters) => (arbo, initData) => {
		if(!initData.dechets_liste || !initData.dechets_liste.length) return arbo;
		_.forEach(arbo, function(value, index){
			if(value.childs){
				value.childs = getters.definitionsArboDechets(value.childs, initData)
			}else if(value.data && value.data.dechets_ids && value.data.dechets_ids.length){
				let definitions = [];
				initData.dechets_liste.forEach(function(dechet){
					if(value.data.dechets_ids.includes(dechet.id) && dechet.description) definitions.push(dechet.description);
				})
				if(definitions.length) value.data.definition = definitions.join('<br>');
			}
		})
		return arbo
	},
	//AS--> Est-ce qu'il y a des outils de carte actifs ? Sert à désactiver la réactivité des parcelles sur la map par exemple
	areMapToolsActive: (state) => {
		return state.map_tools_distance || state.map_tools_surface || ['OutilIsoline', 'OutilItineraire'].includes(state.modale_gauche)
	},
	//AS--> Détermine si l'utilisateur peut éditer un champ d'une entreprise
	userCanEditCompany: (state, getters, rootState) => (field, company_id) => {
		let canEdit = false

		//AS--> Si le champ demandé fait parti des champs autorisés en modification sur cette vue
		if(getters.getFicheInfosEditions.includes(field)){

			//AS--> Si l'autorisation par n'importe quel utilisateur est cochée
			if(state.sourcing.fiche_infos_editions_all_users){
				canEdit = true

			}else{

				//AS--> Si l'entreprise fait parti de mes entreprises
				if(rootState.user.user.mesentreprises && rootState.user.user.mesentreprises.length){
					rootState.user.user.mesentreprises.forEach(function(ent){
						if(ent.id == company_id) canEdit = true;
					})
				}
			}
		}
		return canEdit
	},
	isRefashion: (state) => {
		return state.sourcing && state.sourcing.url_sourcing && ['ecotlc','ecotlctest','refashion'].includes(state.sourcing.url_sourcing)
	},
	getLayersToSave: (state) => {
		return {
			layers_opened: state.layers_opened,
			layers_legendes_opened: state.layers_legendes_opened,
			layers_opacities: state.layers_opacities,
			layer_entreprises_open: state.layer_entreprises.open,
			layer_heatmap_open: state.layer_heatmap.open,
			layer_heatmap_pvrmeta_open: state.layer_heatmap_pvrmeta.open
		}
	},

	hasPrimaryLayersStateChanged: (state) => {
		return !state.layer_entreprises.open || state.layer_heatmap.open || state.layer_heatmap_pvrmeta.open
	},

	hasLayersParamsStateChanged: (state) => {
		let changed = false
		if(state.layers && state.layers.length){
			state.layers.forEach(function(layer){

				//AS--> Si le layer n'est pas affecté à une famille
				if(!layer.famille){
					//AS--> Si le layer n'est pas dans son état d'origine
					if(
						(layer.on && !state.layers_opened.includes(layer.layer))
						|| (!layer.on && state.layers_opened.includes(layer.layer))
					){
						changed = true;
					}
				}
			})
		}
		return changed
	},

	hasLayersFamilyStateChanged: (state) => {
		let changed = false
		if(state.layers && state.layers.length){
			state.layers.forEach(function(layer){

				//AS--> Si le layer est affecté à une famille
				if(layer.famille){
					//AS--> Si le layer n'est pas dans son état d'origine
					if(
						(layer.on && !state.layers_opened.includes(layer.layer))
						|| (!layer.on && state.layers_opened.includes(layer.layer))
					){
						changed = true;
					}
				}
			})
		}
		return changed
	},

	hasFilters_layers: (state, getters, rootState) => (params) => {
		return getters.hasLayersParamsStateChanged
	},
	hasFilters_show_companies: (state, getters, rootState) => (params) => {
		return !state.layer_entreprises.open
	},
	hasFilters_show_heatmap: (state, getters, rootState) => (params) => {
		return state.layer_heatmap.open || state.layer_heatmap_pvrmeta.open
	},
	//AS--> Renvoie l'unité d'une caractéristique via l'id de la caractéristique
	getUniteCarac: (state, getters, rootState) => (carac_id) => {
		
		//AS--> Récupère le déchet principal
		let dechets = getters.getDechets

		if(dechets[0] && dechets[0].filtres && dechets[0].filtres[carac_id]){
			//AS--> Récupère l'id de l'unité sur la caractéristique du déchet principal
			let unite_id = dechets[0].filtres[carac_id].unite_id

			//AS--> Nomencalture des unités
			let unites = state.unites;

			//AS--> Récupère le nom court de l'unité
			let unite = unites[unite_id] != undefined ? unites[unite_id].nc : ''

			return unite
		}
	},
	canFilterSectors: (state, getters, rootState) => {
		let sectors = false;

		//AS--> Si les filtres sont spécifiés dans un Yaml, parcours ces filtres pour vérifier si on a un filtre de type sectors
		if(state.filtres && state.filtres.length){
			state.filtres.forEach(function(filtre){
				if(filtre.filters && filtre.filters.length){
					filtre.filters.forEach(function(f){
						if(f.type == 'sectors') sectors = true;
					})
				}
			})

		//AS--> Sinon, on regarde les filtres activés dans la vue
		}else{
			sectors = getters.getMenusColonneVerte.includes('secteurs');
		}
		return sectors;
	},
	getFamilleNom: (state, getters) => (famille_id) => {
		let famille_nom = false
		state.sourcing.familles.forEach(function(famille){
			if(famille.id == famille_id) famille_nom = famille.titre;
		})
		return famille_nom
	},

	//AS--> Basé sur le state familles issu du sourcing. Il faut qu'il y en ait au moins une qui soit enfant de Transformateurs (6)
	canShowPotentielMeta: (state, getters, rootState) => {
		let show  = false;
		if(state.familles.length){
			state.familles.forEach(function(famille){
				if(famille.parent_id == 6) show = true;
			})
		}
		return show;
	},

	//AS--> Vérifie si l'utilisateur peut modifier les statuts
	canManageStatuses: (state, getters, rootState) => {
		//AS--> Si l'utilisateur est admin ou membre de l'équipe
		return ['admin_vuet', 'inex'].includes(rootState.user.user.type_membre_equipe)
	},

	canClickOnLayers: (state) => {
		return !state.map_get_coords && !state.map_tools_distance && !state.map_tools_surface
	}
}

// actions
const actions = {
	uploadInitApp({ commit, state, rootState, dispatch }) {
		axios.get('/plugin_projet/plugin_projet_iannuaire/init_app')
			.then(function(response) {
				response.data.update = true;
				dispatch('setInitApp', response.data);
			}).catch(function(error) {

			})
	},
	setInitApp({ commit, state, rootState, dispatch, getters }, initData){
		let update = initData.update === true;
		console.log('----- setInitApp -----', update);

		if(update) vi18n.mergeLocaleMessage('fr', initData.i18ns)

		if (initData.env) commit('UI_SET_ENV', initData.env);
		
		if (initData.sourcing.familles) commit('UI_SET_FAMILLES', initData.sourcing.familles);

		if (initData.secteurs) commit('UI_SET_SECTEURS', initData.secteurs);
		
		if (initData.naces_liste) commit('UI_SET_NACE_IDS', initData.naces_liste);
		
		if (initData.dechets){
			let dechets = initData.dechets;
			dechets = getters.reduitArboDechets(dechets, initData);
			dechets = getters.definitionsArboDechets(dechets, initData);
			commit('UI_SET_DECHETS', dechets);
		}
		
		if (initData.dechets_liste) commit('UI_SET_DECHETS_LISTE', initData.dechets_liste);
		
		if (initData.edition_matiere_fo && !update) commit('UI_SET_EDITION_MATIERES_FO', initData.edition_matiere_fo);
		
		if (initData.filtres && !update) commit('UI_SET_FILTRES', initData.filtres);
		
		if (initData.langue && !update) commit('UI_SET_LANGUE', initData.langue);
		
		if (initData.sourcing) commit('UI_SET_SOURCING', initData.sourcing);
		
		if (initData.unites) commit('UI_SET_UNITES', initData.unites);
		
		if (typeof initData.mini_map == 'boolean' && !update) commit('UI_SWITCH_MAP', initData.mini_map == true);
		
		if (initData.tags_liste) commit('UI_SET_TAGS', initData.tags_liste);
		
		if (initData.sourcing.layers && !update) {
			commit('UI_SET_LAYERS', {
				layers: 					initData.sourcing.layers, 
				layer_entreprises: 			initData.sourcing.layer_entreprises, 
				layer_heatmap: 				initData.sourcing.layer_heatmap,
				layer_heatmap_pvrmeta: 		initData.sourcing.layer_heatmap
			});
			if(initData.sourcing.layers){
				initData.sourcing.layers.forEach(function(layer, layer_num){
					if(layer.on) dispatch('toggle_layer', layer.layer);
				})
			}
		}
		
		if (initData.competences) commit('UI_SET_COMPETENCES', initData.competences);
		
		if (initData.marches) commit('UI_SET_MARCHES', initData.marches);

		if (initData.nomenclatures && !update) commit('NOMENCLATURE_SET_NOMENCLATURES', initData.nomenclatures);

		if (initData.sourcing.modes_affichage && !update) commit('UI_SET_MODE_AFFICHAGE', initData.sourcing.modes_affichage.split(',')[0]);

		if (initData.images_ip && !update) commit('UI_SET_IMAGES_IP', initData.images_ip);

		if(!update){
			dispatch('init_user_data', initData);

			//AS--> Arrive directement sur la liste si demandé par le sourcing
			if(initData.sourcing.acces_direct_categorie_id){
				dispatch('setMainDechetId', initData.sourcing.acces_direct_categorie_id)
				dispatch('initDefaultFilters');

				if(initData.sourcing.mono_compte){
					commit('UI_SET_PAGE', 'results');
				}else{
					if(!rootState.user.user.nom){
						commit('UI_SET_MODAL', { modal: 'connection' })
					}else{
						dispatch('user_after_connection');
					}
				}
			}else{
				//AS--> Si l'utilisateur était déjà connecté
				if(rootState.user.user.nom){
					dispatch('user_after_connection')
				}else{
					dispatch('initDefaultFilters')
				}
			}
		}

		commit('UI_SET_SAVE_USER_LOADING', false);
	},
	openZoneFilter({ commit, state }){
		commit('UI_SET_SHOW_FILTERS', { filters: false })
		commit('UI_SET_CONTENT', {content: 'map'});
	},
	openGraph({ commit, state }){
		if (state.content != 'graph') {
			commit('UI_SET_CONTENT', {content: 'graph'});
		}
	},
	openCarte({ commit, state }){
		if (state.content != 'result') {
			commit('UI_SET_CONTENT', {content: 'result'});
		}
	},
	uiSetDevice({ commit }) {
		let device = '';
		if (window.innerWidth < 768) {
			device = 'mobile'
			commit('UI_SWITCH_MAP', false);
		} else if (window.innerWidth <= 1024) {
			device = 'tablet'
		} else {
			device = 'desktop'
		}
		commit('UI_SET_DEVICE', device)
	},
	close_modal({ commit }) {
		commit('UI_SET_MODAL', { modal: null })
	},
	btnApply({ commit }) {
		commit('UI_SET_MENU', false)
		commit('UI_SET_SHOW_FILTERS', { filters: false })
	},
	open_modal({ commit }, code) {
		let params = {};
		if (_.isObject(code)) {
			params = code.params;
			code = code.code;
		}
		commit('UI_SET_MODAL', { modal: code, modal_params: params })
	},
	select_company({ commit, state }, company_id) {
		let new_selected_companies = state.selected_companies.filter(id => id)
		if (state.selected_companies.includes(company_id)) {
			new_selected_companies = new_selected_companies.filter(id => id != company_id)
		} else {
			new_selected_companies.push(company_id)
		}
		commit('UI_SELECTED_COMPANIES', new_selected_companies)
	},
	toggle_mini_map({ commit, state, rootState }) {
		let mini_map = !state.map_open ? 1 : 0;
		axios.get('/plugin_projet/plugin_projet_iannuaire/set_mini_map/' + mini_map)
			.then(function(response) {

			}).catch(function(error) {

			})
		commit('UI_SWITCH_MAP', !state.map_open);
	},
	toggle_markers({ commit, state, rootState }) {
		commit('UI_SWITCH_LAYER_MARKERS', !state.layer_entreprises.open);
	},
	toggle_heatmap({ commit, state, rootState }) {
		commit('UI_SWITCH_LAYER_HEATMAP', !state.layer_heatmap.open);
	},
	toggle_heatmap_pvrmeta({ commit, state, rootState }) {
		commit('UI_SWITCH_LAYER_HEATMAP_PVRMETA', !state.layer_heatmap_pvrmeta.open);
	},
	toggle_layer({ commit, state, rootState, dispatch }, layer_layer) {
		let layers_opened = state.layers_opened;
		let layer_opened = false;
		layers_opened.forEach(function(_layer_layer, i){
			if(layer_layer == _layer_layer) layer_opened = i;
		})

		//AS--> Si le calque est déjà ouvert, on le ferme
		if(layer_opened !== false){
			layers_opened.splice(layer_opened, 1);

			//AS--> On ferme toutes les infobulles relatives à ce calque
			dispatch('remove_map_custom_info_window_multiple_by_layer', layer_layer);

		//AS--> Sinon on l'ouvre
		}else{
			layers_opened.push(layer_layer);
		}

		commit('UI_SWITCH_LAYER', layers_opened);
		
		//AS--> On supprime la référence à la recherche
		if(!state.recherche_edition) dispatch('exitSearch');
	},
	// legende : {layer_id:xxx, legende_key:xxx}
	toggle_layer_legende_opened({ commit, state, rootState }, legende) {
		let layers_legendes_opened = _.clone(state.layers_legendes_opened);
		if(layers_legendes_opened.includes(legende)){
			layers_legendes_opened = layers_legendes_opened.filter(a => a !== legende)
		}else{
			layers_legendes_opened.push(legende);
		}
		commit('UI_SWITCH_LAYERS_LEGENDES_OPENED', layers_legendes_opened);	
	},
	// legende : {layer_id:xxx, opacity:xxx}
	change_layer_opacity({ commit, state, rootState }, layer) {
		let layers_opacities = _.clone(state.layers_opacities);
		if(layer.opacity == null){
			delete layers_opacities[layer.layer_id]
		}else{
			layers_opacities[layer.layer_id] = layer.opacity
		}
		commit('UI_LAYERS_OPACITIES', layers_opacities);	
	},
	toggle_map_layer({ commit, state, rootState }, layer_num) {
		commit('UI_SWITCH_MAP_LAYER', layer_num);
	},
	layer_loaded({ commit, state, rootState }, layer_num) {
		let layers_loaded = state.layers_loaded;
		if(_.indexOf(layers_loaded, layer_num) == -1){
			layers_loaded.push(layer_num);
			commit('UI_LAYER_LOADED', layers_loaded);
		}
	},
	layer_unloaded({ commit, state, rootState }, layer_num) {
		let layers_loaded = _.clone(state.layers_loaded);
		_.remove(layers_loaded, function(n){return n == layer_num;});
		commit('UI_LAYER_LOADED', layers_loaded);	
	},
	layer_zoom_alert_add({ commit, state, rootState }, layer_num) {
		let layers_zoom_alert = state.layers_zoom_alert;
		if(_.indexOf(layers_zoom_alert, layer_num) == -1){
			layers_zoom_alert.push(layer_num);
			commit('UI_LAYERS_ZOOM_ALERT', layers_zoom_alert);
		}
	},
	layer_zoom_alert_del({ commit, state, rootState }, layer_num) {
		let layers_zoom_alert = _.clone(state.layers_zoom_alert);
		_.remove(layers_zoom_alert, function(n){return n == layer_num;});
		commit('UI_LAYERS_ZOOM_ALERT', layers_zoom_alert);	
	},
	toggle_full_screen({ commit, state, rootState }) {

		if (!state.full_screen) {
			let elem = document.getElementById("app_content");
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.mozRequestFullScreen) { /* Firefox */
				elem.mozRequestFullScreen();
			} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) { /* IE/Edge */
				elem.msRequestFullscreen();
			}
		}else{
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) { /* Firefox */
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) { /* IE/Edge */
				document.msExitFullscreen();
			}
		}
	},
	switch_all_companies_ids({ commit, state, rootState }) {
		let ids = []
		let all_ids = rootState.entreprises.companies_ids
		let ids_in = _.intersection(all_ids, state.selected_companies)
		if (ids_in.length != all_ids.length) {
			ids = all_ids
		}
		commit('UI_SELECTED_COMPANIES', ids);
	},
	set_contact_id_phoned({ commit, state, rootState }, contact_id) {
		commit('UI_SET_LAST_CONTACT_PHONED', contact_id);
	},
	set_loading({ commit, state, rootState }, loading) {
		commit('UI_SET_LOADING', loading);
	},
	set_surface({ commit, state, rootState }, surface) {
		commit('UI_SET_SURFACE_ID', surface.id);
		commit('UI_SET_MAP_TOOLS_SURFACE', true);
		commit('UI_SET_CONTENT', {content: 'result'});
	},
	set_map_zone_google({ commit, state, rootState }, infos) {
		commit('UI_SET_MAP_ZONE_GOOGLE', infos);
	},
	set_map_state({ commit, state, rootState }, infos) {
		commit('UI_SET_MAP_STATE', infos);
	},
	set_streetmap_visible({ commit, state, rootState }, visible_bool) {
		commit('UI_SET_STREETMAP_VISIBLE', visible_bool);
	},
	set_map_adresse({ commit, state, rootState }, map_adresse) {
		commit('UI_SET_MAP_ADRESSE', map_adresse);
	},
	switch_map_tools_distance({ commit, state, rootState }) {
		//AS--> Si on s'apprête à activer distance et que surface est encore activé, on le désactive avant
		if(state.map_tools_distance == false && state.map_tools_surface == true) commit('UI_SET_MAP_TOOLS_SURFACE', false);
		//AS--> Obligé de désynchro à cause de la modif de UI_SET_MAP_TOOLS_SURFACE
		setTimeout(function(){
			commit('UI_SET_MAP_TOOLS_DISTANCE', !state.map_tools_distance);
		},10)
	},
	switch_map_tools_surface({ commit, state, rootState }) {
		//AS--> Si on s'apprête à activer surface et que distance est encore activé, on le désactive avant
		if(state.map_tools_surface == false && state.map_tools_distance == true) commit('UI_SET_MAP_TOOLS_DISTANCE', false);
		//AS--> Obligé de désynchro à cause de la modif de UI_SET_MAP_TOOLS_DISTANCE
		setTimeout(function(){
			commit('UI_SET_MAP_TOOLS_SURFACE', !state.map_tools_surface);
		},10)
	},
	set_map_itineraire({ commit, state, rootState }, itineraire) {
		commit('UI_SET_MAP_ITINERAIRE_RESULT', itineraire.result)
		commit('UI_SET_MAP_ITINERAIRE_GEOJSON', null)
		setTimeout(function(){
			commit('UI_SET_MAP_ITINERAIRE_GEOJSON', itineraire.geojson)
		}, 1)
	},
	set_map_isoline({ commit, state, rootState }, isoline) {
		commit('UI_SET_MAP_ISOLINE_RESULT', isoline.result)
		commit('UI_SET_MAP_ISOLINE_GEOJSON', null)
		setTimeout(function(){
			commit('UI_SET_MAP_ISOLINE_GEOJSON', isoline.geojson)
		}, 1)
	},
	set_map_coords({ commit, state, rootState }, etat) {
		if(etat) commit('UI_SET_MAP_COORDS_POINTED', null)
		commit('UI_SET_MAP_GET_COORDS', etat)
	},
	set_map_coords_pointed({ commit, state, rootState }, coords) {
		commit('UI_SET_MAP_COORDS_POINTED', coords)
	},
	set_mode_affichage({ commit, state, rootState }, mode) {
		commit('UI_SET_MODE_AFFICHAGE', mode)
	},
	add_recherche_supprId({ commit, state }, id) {
		if(!state.recherche_supprIds.includes(id)){
			let recherche_supprIds = _.clone(state.recherche_supprIds)
			recherche_supprIds.push(id)
			commit('UI_SET_RECHERCHE_SUPPRIDS', recherche_supprIds)
		}
	},
	remove_recherche_supprId({ commit, state }, id) {
		if(state.recherche_supprIds.includes(id)){
			let recherche_supprIds = _.clone(state.recherche_supprIds)
			_.pull(recherche_supprIds, id)
			commit('UI_SET_RECHERCHE_SUPPRIDS', recherche_supprIds)
		}
	},

	resetPrimaryLayers({ commit, state }) {
		if(!state.layer_entreprises.open) commit('UI_SWITCH_LAYER_MARKERS', true)
		if(state.layer_heatmap.open) commit('UI_SWITCH_LAYER_HEATMAP', false)
		if(state.layer_heatmap_pvrmeta.open) commit('UI_SWITCH_LAYER_HEATMAP_PVRMETA', false)
	},
	resetParamsLayers({ commit, state, rootState, dispatch }) {
		let layers_opened = state.layers_opened
		if(state.layers && state.layers.length){
			state.layers.forEach(function(layer){
				if(!layer.famille){
					if(layer.on && !layers_opened.includes(layer.layer)){
						layers_opened.push(layer.layer)
					}
					if(!layer.on && layers_opened.includes(layer.layer)){
						layers_opened.splice(layers_opened.indexOf(layer.layer), 1)
					}
				}
			})
		}
		commit('UI_SWITCH_LAYER', layers_opened)
		//AS--> On supprime la référence à la recherche
		if(!state.recherche_edition) dispatch('exitSearch');
	},
	resetFamilyLayers({ commit, state, rootState, dispatch }) {
		let layers_opened = state.layers_opened
		if(state.layers && state.layers.length){
			state.layers.forEach(function(layer){
				if(layer.famille){
					if(layer.on && !layers_opened.includes(layer.layer)){
						layers_opened.push(layer.layer)
					}
					if(!layer.on && layers_opened.includes(layer.layer)){
						layers_opened.splice(layers_opened.indexOf(layer.layer), 1)
					}
				}
			})
		}
		commit('UI_SWITCH_LAYER', layers_opened)
		//AS--> On supprime la référence à la recherche
		if(!state.recherche_edition) dispatch('exitSearch');
	},
	exitSearch({ commit, state, rootState, dispatch }) {
		commit('UI_SET_RECHERCHE', null)
		commit('UI_SET_RECHERCHE_ID', null)
		dispatch('resetSubstractSearch');
	},
	loadStatuses({ commit, state, rootState, dispatch }) {
		axios.get('api=inex/views/{view_code}/statuses')
			.then(function(response) {
				commit('UI_SET_STATUSES', response.data);
			}).catch(function(error) {
				console.log('error', error)
			})
	},
	
	add_map_custom_info_window_multiple({ commit, state }, infos) {
		let map_custom_info_window_multiple = _.cloneDeep(state.map_custom_info_window_multiple);

		let _default = {
			position: {
				lat: 0,
				lng: 0
			},
			options: {},
			contenu: '',
			class: '',
		}
		infos = {..._default, ...infos};

		//AS--> Ajoute une index unique à l'information
		infos.index = Date.now() + Math.random().toString(36).substring(2, 9);

		//AS--> Si aucune information n'existe avec le même contenu, on l'ajoute
		if(!map_custom_info_window_multiple.some(info => info.contenu === infos.contenu)){
			map_custom_info_window_multiple.push(infos);
		}

		commit('UI_SET_MAP_CUSTOM_INFO_WINDOW_MULTIPLE', map_custom_info_window_multiple);
	},
	remove_map_custom_info_window_multiple({ commit, state }, info_index_suppr) {
		let map_custom_info_window_multiple = _.cloneDeep(state.map_custom_info_window_multiple);
		map_custom_info_window_multiple = map_custom_info_window_multiple.filter(info => info.index !== info_index_suppr);
		commit('UI_SET_MAP_CUSTOM_INFO_WINDOW_MULTIPLE', map_custom_info_window_multiple);
	},
	remove_map_custom_info_window_multiple_by_layer({ commit, state }, layer) {
		let map_custom_info_window_multiple = _.cloneDeep(state.map_custom_info_window_multiple);
		map_custom_info_window_multiple = map_custom_info_window_multiple.filter(info => info.layer !== layer);
		commit('UI_SET_MAP_CUSTOM_INFO_WINDOW_MULTIPLE', map_custom_info_window_multiple);
	},
	reset_map_custom_info_window_multiple({ commit, state }) {
		commit('UI_SET_MAP_CUSTOM_INFO_WINDOW_MULTIPLE', [])
	},

	modePdfOn({ commit}, mode) {
		if(typeof mode == 'undefined') mode = true;
		commit('UI_SET_SCREEN_PDF_ON', mode);
	},
}

// mutations
const mutations = {
	[types.UI_SET_ENV](state, env) {
		console.time('UI_SET_ENV');
		state.env = env
		console.timeEnd('UI_SET_ENV');
	},
	[types.UI_SET_DEVICE](state, device) {
		console.time('UI_SET_DEVICE');
		state.device = device
		console.timeEnd('UI_SET_DEVICE');
	},
	[types.UI_SET_PAGE](state, page) {
		console.time('UI_SET_PAGE');
		state.page = page
		console.timeEnd('UI_SET_PAGE');
	},
	[types.UI_SET_MODAL](state, { modal, modal_params }) {
		console.time('UI_SET_MODAL');
		if(modal != null){
			if(state.modal != null && modal != state.modal){
				//AS--> Ne mets que la modale d'intro en stand-by pour le moment
				if(['intro'].includes(state.modal)){
					state.modal_standby = state.modal;
				}
			}
			state.modal = modal
		}else{
			if(state.modal_standby != null){
				state.modal         = state.modal_standby;
				state.modal_standby = null;
			}else{
				state.modal = modal
			}
		}
		state.modal_params = modal_params
		console.timeEnd('UI_SET_MODAL');
	},
	[types.UI_SET_MODAL_CONTENT](state, content) {
		state.modal_content = content
	},
	[types.UI_SET_CONTENT](state, { content }) {
		if(state.content != content){
			console.time('UI_SET_CONTENT');
			state.content = content
			if (this.$hj) this.$hj('vpv', content)
			console.timeEnd('UI_SET_CONTENT');
			console.log(content);
		}
	},
	[types.UI_SET_MONENTREPRISE_SUBCONTENT](state, { subcontent }) {
		console.time('UI_SET_MONENTREPRISE_SUBCONTENT');
		state.monentreprise_subcontent = subcontent
		console.timeEnd('UI_SET_MONENTREPRISE_SUBCONTENT');
		console.log('Sous menu : ' + subcontent);
	},
	[types.UI_SET_ALLFAVORIS_SUBCONTENT](state, subcontent) {
		console.time('UI_SET_ALLFAVORIS_SUBCONTENT');
		state.allfavoris_subcontent = subcontent
		console.timeEnd('UI_SET_ALLFAVORIS_SUBCONTENT');
		console.log('Sous menu : ' + subcontent);
	},
	[types.UI_SET_SHOW_FILTERS](state, { filters }) {
		console.time('UI_SET_SHOW_FILTERS');
		state.show_filters = filters
		setTimeout(function(){
			window.dispatchEvent(new Event('resize'));
		},250)
		console.timeEnd('UI_SET_SHOW_FILTERS');
	},
	[types.UI_SET_SHOW_FAVORIS_MENU](state, show) {
		console.time('UI_SET_SHOW_FAVORIS_MENU');
		state.show_favoris_menus = show
		console.timeEnd('UI_SET_SHOW_FAVORIS_MENU');
	},
	[types.UI_SET_FILTER_INDEX](state, { filter_index }) {
		console.time('UI_SET_FILTER_INDEX');
		state.show_filter_index = filter_index
		console.timeEnd('UI_SET_FILTER_INDEX');
	},
	[types.UI_SWITCH_MAP](state, bool) {
		console.time('UI_SWITCH_MAP');
		state.map_open = bool
		console.timeEnd('UI_SWITCH_MAP');
	},
	[types.UI_SWITCH_LAYER_MARKERS](state, bool) {
		console.time('UI_SWITCH_LAYER_MARKERS');
		state.layer_entreprises.open = bool
		console.timeEnd('UI_SWITCH_LAYER_MARKERS');
	},
	[types.UI_SWITCH_LAYER_HEATMAP](state, bool) {
		console.time('UI_SWITCH_LAYER_HEATMAP');
		state.layer_heatmap.open = bool
		console.timeEnd('UI_SWITCH_LAYER_HEATMAP');
	},
	[types.UI_SWITCH_LAYER_HEATMAP_PVRMETA](state, bool) {
		console.time('UI_SWITCH_LAYER_HEATMAP_PVRMETA');
		state.layer_heatmap_pvrmeta.open = bool
		console.timeEnd('UI_SWITCH_LAYER_HEATMAP_PVRMETA');
	},
	[types.UI_SWITCH_LAYER](state, layers_opened) {
		console.time('UI_SWITCH_LAYER');
		state.layers_opened = layers_opened
		console.timeEnd('UI_SWITCH_LAYER');
	},
	[types.UI_SWITCH_LAYERS_LEGENDES_OPENED](state, layers_legendes_opened) {
		console.time('UI_SWITCH_LAYERS_LEGENDES_OPENED');
		state.layers_legendes_opened = layers_legendes_opened
		console.timeEnd('UI_SWITCH_LAYERS_LEGENDES_OPENED');
	},
	[types.UI_LAYERS_OPACITIES](state, layers_opacities) {
		console.time('UI_LAYERS_OPACITIES');
		state.layers_opacities = layers_opacities
		console.timeEnd('UI_LAYERS_OPACITIES');
	},
	[types.UI_SWITCH_MAP_LAYER](state, layer_opened) {
		console.time('UI_SWITCH_MAP_LAYER');
		state.map_layer_opened = layer_opened
		console.timeEnd('UI_SWITCH_MAP_LAYER');
	},
	[types.UI_LAYER_LOADED](state, layers_loaded) {
		console.time('UI_LAYER_LOADED');
		state.layers_loaded = layers_loaded
		console.timeEnd('UI_LAYER_LOADED');
	},
	[types.UI_LAYERS_ZOOM_ALERT](state, layers_zoom_alert) {
		console.time('UI_LAYERS_ZOOM_ALERT');
		state.layers_zoom_alert = layers_zoom_alert
		console.timeEnd('UI_LAYERS_ZOOM_ALERT');
	},
	[types.UI_SWITCH_FULL_SCREEN](state, bool) {
		console.time('UI_SWITCH_FULL_SCREEN');
		state.full_screen = bool
		console.timeEnd('UI_SWITCH_FULL_SCREEN');
	},
	[types.UI_SET_PLAGE](state, plage) {
		console.time('UI_SET_PLAGE');
		state.plage = plage;
		console.timeEnd('UI_SET_PLAGE');
	},
	[types.UI_SET_MENU](state, menu) {
		console.time('UI_SET_MENU');
		state.menu_open = menu;
		console.timeEnd('UI_SET_MENU');
	},
	[types.UI_SET_GET](state, get) {
		console.time('UI_SET_GET');
		state._get = get;
		console.timeEnd('UI_SET_GET');
	},
	[types.UI_SET_SECTEURS](state, secteurs) {
		console.time('UI_SET_SECTEURS');
		state.secteurs = secteurs;
		console.timeEnd('UI_SET_SECTEURS');
	},
	[types.UI_SET_TAGS](state, tags) {
		console.time('UI_SET_TAGS');
		state.tags = tags;
		console.timeEnd('UI_SET_TAGS');
	},
	[types.UI_SET_COMPETENCES](state, competences) {
		console.time('UI_SET_COMPETENCES');
		state.competences = competences;
		console.timeEnd('UI_SET_COMPETENCES');
	},
	[types.UI_SET_MARCHES](state, marches) {
		console.time('UI_SET_MARCHES');
		state.marches = marches;
		console.timeEnd('UI_SET_MARCHES');
	},
	[types.UI_SET_LAYERS](state, layers) {
		console.time('UI_SET_LAYERS');
		if(typeof layers.layers != 'undefined') 				state.layers                   		= layers.layers;
		if(typeof layers.layer_entreprises != 'undefined') 		state.layer_entreprises.enable 		= layers.layer_entreprises;
		if(typeof layers.layer_heatmap != 'undefined') 			state.layer_heatmap.enable     		= layers.layer_heatmap;
		if(typeof layers.layer_heatmap_pvrmeta != 'undefined') 	state.layer_heatmap_pvrmeta.enable 	= layers.layer_heatmap_pvrmeta;
		console.timeEnd('UI_SET_LAYERS');
	},
	[types.UI_SET_LAYER_DATA](state, layer) {
		console.time('UI_SET_LAYER_DATA');
		state.layers_data[layer.layer_num] = layer.data;
		console.timeEnd('UI_SET_LAYER_DATA');
	},
	[types.UI_SET_NACE_IDS](state, nace_ids) {
		console.time('UI_SET_NACE_IDS');
		state.code_naces = nace_ids;
		console.timeEnd('UI_SET_NACE_IDS');
	},
	[types.UI_SET_FAMILLES](state, familles) {
		console.time('UI_SET_FAMILLES');
		state.familles = familles;
		console.timeEnd('UI_SET_FAMILLES');
	},
	[types.UI_SET_DECHETS](state, dechets) {
		console.time('UI_SET_DECHETS');
		dechets = _.sortBy(dechets, [function(d) { return d.data.ordre; }]);
		state.dechets = dechets;
		console.timeEnd('UI_SET_DECHETS');
	},
	[types.UI_SET_DECHETS_LISTE](state, dechets) {
		console.time('UI_SET_DECHETS_LISTE');
		dechets = _.sortBy(dechets, [function(d) { return d.code }]);
		state.dechets_liste = dechets;
		console.timeEnd('UI_SET_DECHETS_LISTE');
	},
	[types.UI_SET_EDITION_MATIERES_FO](state, edition_matiere_fo) {
		console.time('UI_SET_EDITION_MATIERES_FO');
		state.edition_matiere_fo = edition_matiere_fo;
		console.timeEnd('UI_SET_EDITION_MATIERES_FO');
	},
	[types.UI_SET_FILTRES](state, filtres) {
		console.time('UI_SET_FILTRES');
		state.filtres = filtres;
		console.timeEnd('UI_SET_FILTRES');
	},
	[types.UI_SET_LANGUE](state, langue) {
		console.time('UI_SET_LANGUE');
		state.langue = langue;
		console.timeEnd('UI_SET_LANGUE');
		console.log(langue);
	},
	[types.UI_SET_COMPANIES_SAVED](state, companies) {
		console.time('UI_SET_COMPANIES_SAVED');
		state.companies_saved = companies;
		console.timeEnd('UI_SET_COMPANIES_SAVED');
	},
	[types.UI_SELECTED_COMPANIES](state, selected_companies) {
		console.time('UI_SELECTED_COMPANIES');
		state.selected_companies = selected_companies;
		console.timeEnd('UI_SELECTED_COMPANIES');
	},
	[types.UI_SET_SOURCING](state, sourcing) {
		console.time('UI_SET_SOURCING');
		state.sourcing = sourcing;
		console.timeEnd('UI_SET_SOURCING');
	},
	[types.UI_SET_DECHETS_COLUMNS](state, dechets_columns) {
		console.time('UI_SET_DECHETS_COLUMNS');
		state.dechets_columns = dechets_columns;
		console.timeEnd('UI_SET_DECHETS_COLUMNS');
	},
	[types.UI_SET_UI_CUSTOM_FILTRERS](state, custom_filters) {
		console.time('UI_SET_UI_CUSTOM_FILTRERS');
		state.custom_filters = custom_filters;
		console.timeEnd('UI_SET_UI_CUSTOM_FILTRERS');
	},
	[types.UI_SET_UI_VARIABLES_FILTRERS](state, variables_filters) {
		console.time('UI_SET_UI_VARIABLES_FILTRERS');
		state.variables_filters = variables_filters;
		console.timeEnd('UI_SET_UI_VARIABLES_FILTRERS');
	},
	[types.UI_SET_UNITES](state, unites) {
		console.time('UI_SET_UNITES');
		state.unites = unites;
		console.timeEnd('UI_SET_UNITES');
	},
	[types.UI_SET_LAST_CONTACT_PHONED](state, last_contact_id_phoned) {
		console.time('UI_SET_LAST_CONTACT_PHONED');
		state.last_contact_id_phoned = last_contact_id_phoned;
		console.timeEnd('UI_SET_LAST_CONTACT_PHONED');
	},
	[types.UI_SET_LOADING](state, loading) {
		console.time('UI_SET_LOADING');
		state.loading = loading;
		console.timeEnd('UI_SET_LOADING');
	},
	[types.UI_SET_LOADING_MAP](state, loading) {
		console.time('UI_SET_LOADING_MAP');
		state.loading_map = loading;
		console.timeEnd('UI_SET_LOADING_MAP');
	},
	[types.UI_SET_LOADING_FIRST](state, loading) {
		console.time('UI_SET_LOADING_FIRST');
		state.loading_first = loading;
		console.timeEnd('UI_SET_LOADING_FIRST');
	},
	[types.UI_SET_EXPORT_ID](state, export_id) {
		console.time('UI_SET_EXPORT_ID');
		state.export_id = export_id;
		console.timeEnd('UI_SET_EXPORT_ID');
	},
	[types.UI_SET_EXPORT_TYPE](state, export_type) {
		console.time('UI_SET_EXPORT_TYPE');
		state.export_type = export_type;
		console.timeEnd('UI_SET_EXPORT_TYPE');
	},
	[types.UI_SET_MAP_SIZE](state, map_size) {
		console.time('UI_SET_MAP_SIZE');
		state.map_size = map_size;
		console.timeEnd('UI_SET_MAP_SIZE');
	},
	[types.UI_SET_UI_CAPACITES](state, capacites) {
		console.time('UI_SET_UI_CAPACITES');
		state.capacites = capacites;
		console.timeEnd('UI_SET_UI_CAPACITES');
	},
	[types.UI_SET_SURFACE_ID](state, surface_id) {
		console.time('UI_SET_SURFACE_ID');
		state.surface_id = surface_id;
		console.timeEnd('UI_SET_SURFACE_ID');
	},
	[types.UI_SET_MAP_ZONE_GOOGLE](state, infos) {
		console.time('UI_SET_MAP_ZONE_GOOGLE');
		state.map_zone_google = infos;
		console.log('UI_SET_MAP_ZONE_GOOGLE', infos);
		console.timeEnd('UI_SET_MAP_ZONE_GOOGLE');
	},
	[types.UI_SET_MAP_STATE](state, infos) {
		console.time('UI_SET_MAP_STATE');
		state.map_state = infos;
		console.timeEnd('UI_SET_MAP_STATE');
	},
	[types.UI_SET_STREETMAP_VISIBLE](state, visile_bool) {
		console.time('UI_SET_STREETMAP_VISIBLE');
		state.streetMapIsVisible = visile_bool;
		console.timeEnd('UI_SET_STREETMAP_VISIBLE');
	},
	[types.UI_SET_RECHERCHE](state, recherche) {
		console.time('UI_SET_RECHERCHE');
		state.recherche = recherche;
		console.timeEnd('UI_SET_RECHERCHE');
	},
	[types.UI_SET_RECHERCHE_ID](state, recherche_id) {
		console.time('UI_SET_RECHERCHE_ID ' + recherche_id);
		state.recherche_id = recherche_id;
		if(!recherche_id){
			state.recherche_supprIds = []
		}
		console.timeEnd('UI_SET_RECHERCHE_ID ' + recherche_id);
	},
	[types.UI_UPLOAD_IMAGE](state, image) {
		console.time('UI_UPLOAD_IMAGE');
		state.upload_image = image;
		console.log(image);
		console.timeEnd('UI_UPLOAD_IMAGE');
	},
	[types.UI_SET_MATIERE](state, matiere) {
		console.time('UI_SET_MATIERE');
		state.matiere = matiere;
		console.timeEnd('UI_SET_MATIERE');
	},
	[types.UI_SET_SAVE_USER_LOADING](state, etat) {
		console.time('UI_SET_SAVE_USER_LOADING');
		state.save_user_loading = etat;
		console.timeEnd('UI_SET_SAVE_USER_LOADING');
	},
	[types.UI_SET_BARRE_RECHERCHE_ACTIVE](state, barre) {
		console.time('UI_SET_BARRE_RECHERCHE_ACTIVE');
		state.barre_recherche_active = barre;
		console.timeEnd('UI_SET_BARRE_RECHERCHE_ACTIVE');
	},
	[types.UI_SET_MAP_ADRESSE](state, adresse) {
		console.time('UI_SET_MAP_ADRESSE');
		state.map_adresse = adresse;
		console.timeEnd('UI_SET_MAP_ADRESSE');
	},
	[types.UI_SET_CLASS_IMPRESSION](state, _class) {
		console.time('UI_SET_CLASS_IMPRESSION');
		state.class_impression = _class;
		console.timeEnd('UI_SET_CLASS_IMPRESSION');
	},
	[types.UI_ADD_LOADING_LAYER](state) {
		console.time('UI_ADD_LOADING_LAYER');
		state.loading_layer = state.loading_layer +1;
		console.timeEnd('UI_ADD_LOADING_LAYER');
	},
	[types.UI_REMOVE_LOADING_LAYER](state) {
		console.time('UI_REMOVE_LOADING_LAYER');
		let loading = state.loading_layer -1;
		if(loading < 0) loading = 0;
		state.loading_layer = loading
		console.timeEnd('UI_REMOVE_LOADING_LAYER');
	},
	[types.UI_SET_MAP_TOOLS_DISTANCE](state, active) {
		console.time('UI_SET_MAP_TOOLS_DISTANCE');
		state.map_tools_distance = active
		console.timeEnd('UI_SET_MAP_TOOLS_DISTANCE');
	},
	[types.UI_SET_MAP_TOOLS_SURFACE](state, active) {
		console.time('UI_SET_MAP_TOOLS_SURFACE');
		state.map_tools_surface = active
		console.timeEnd('UI_SET_MAP_TOOLS_SURFACE');
	},
	[types.UI_SET_MODALE_GAUCHE](state, modale) {
		console.time('UI_SET_MODALE_GAUCHE');
		state.modale_gauche = modale
		console.timeEnd('UI_SET_MODALE_GAUCHE');
	},
	[types.UI_SET_MAP_ITINERAIRE_RESULT](state, result) {
		console.time('UI_SET_MAP_ITINERAIRE_RESULT');
		state.map_itineraire_result = result
		console.timeEnd('UI_SET_MAP_ITINERAIRE_RESULT');
	},
	[types.UI_SET_MAP_ITINERAIRE_GEOJSON](state, geojson) {
		console.time('UI_SET_MAP_ITINERAIRE_GEOJSON');
		state.map_itineraire_geojson = geojson
		console.timeEnd('UI_SET_MAP_ITINERAIRE_GEOJSON');
	},
	[types.UI_SET_MAP_ISOLINE_RESULT](state, result) {
		console.time('UI_SET_MAP_ISOLINE_RESULT');
		state.map_isoline_result = result
		console.timeEnd('UI_SET_MAP_ISOLINE_RESULT');
	},
	[types.UI_SET_MAP_ISOLINE_GEOJSON](state, geojson) {
		console.time('UI_SET_MAP_ISOLINE_GEOJSON');
		state.map_isoline_geojson = geojson
		console.timeEnd('UI_SET_MAP_ISOLINE_GEOJSON');
	},
	[types.UI_SET_MAP_GET_COORDS](state, etat) {
		console.time('UI_SET_MAP_GET_COORDS');
		state.map_get_coords = etat
		console.timeEnd('UI_SET_MAP_GET_COORDS');
	},
	[types.UI_SET_MAP_COORDS_POINTED](state, geojson) {
		console.time('UI_SET_MAP_COORDS_POINTED');
		state.map_coords_pointed = geojson
		console.timeEnd('UI_SET_MAP_COORDS_POINTED');
	},
	[types.UI_SET_MAP_PIN_POINTED](state, geojson) {
		console.time('UI_SET_MAP_PIN_POINTED');
		state.map_pin_pointed = geojson
		console.timeEnd('UI_SET_MAP_PIN_POINTED');
	},
	[types.UI_SET_MODE_AFFICHAGE](state, mode) {
		console.time('UI_SET_MODE_AFFICHAGE');
		state.mode_affichage = mode
		console.timeEnd('UI_SET_MODE_AFFICHAGE');
	},
	[types.UI_SET_IMAGES_IP](state, images) {
		console.time('UI_SET_IMAGES_IP');
		state.images_ip = images
		console.timeEnd('UI_SET_IMAGES_IP');
	},
	[types.UI_SET_SCREEN_PDF_ON](state, etat) {
		console.time('UI_SET_SCREEN_PDF_ON');
		state.screenPdfOn = etat
		console.timeEnd('UI_SET_SCREEN_PDF_ON');
	},
	[types.UI_RECHERCHE_EDITION](state, edition) {
		console.time('UI_RECHERCHE_EDITION');
		state.recherche_edition = edition
		console.timeEnd('UI_RECHERCHE_EDITION');
	},
	[types.UI_SET_RECHERCHE_SUPPRIDS](state, ids) {
		console.time('UI_SET_RECHERCHE_SUPPRIDS');
		state.recherche_supprIds = ids
		console.timeEnd('UI_SET_RECHERCHE_SUPPRIDS');
	},
	[types.UI_SET_FIRST_DATA_LOADED](state) {
		console.time('UI_SET_FIRST_DATA_LOADED');
		state.first_data_loaded = true
		console.timeEnd('UI_SET_FIRST_DATA_LOADED');
	},
	[types.UI_SET_MENU_SOUS_MENU_TIMESTAMP_OPENED](state, timestamp) {
		state.menu_sous_menu_timestamp_opened = timestamp
	},
	[types.UI_SET_STATUSES](state, statuses) {
		state.statuses = statuses
	},
	[types.UI_UPDATE_ZOOMAUTO](state, report) {
		if(
			!['result', 'map'].includes(state.content) 
			|| (state.modal && !['intro'].includes(state.modal)) 
			|| (typeof report == 'boolean' && report == true)){
			state.zoomAuto = 'report'
		}else{
			let time = new Date().getTime()
			state.zoomAuto = time
		}
		console.log('UI_UPDATE_ZOOMAUTO', state.content, state.zoomAuto);
	},
	[types.UI_SAVELAST_ZOOMAUTO](state) {
		console.log('UI_SAVELAST_ZOOMAUTO');
		state.zoomAuto_last = state.zoomAuto
	},
	[types.UI_SET_MAP_CUSTOM_INFO_WINDOW](state, infos) {
		console.time('UI_SET_MAP_CUSTOM_INFO_WINDOW');
		let _default = {
			position: {
				lat: 0,
				lng: 0
			},
			options: {},
			contenu: '',
			class: '',
		}
		state.map_custom_info_window = infos ? {..._default, ...infos} : null;
		console.timeEnd('UI_SET_MAP_CUSTOM_INFO_WINDOW');
	},
	[types.UI_SET_MAP_CUSTOM_INFO_WINDOW_MULTIPLE](state, infos) {
		console.time('UI_SET_MAP_CUSTOM_INFO_WINDOW_MULTIPLE');
		state.map_custom_info_window_multiple = infos;
		console.timeEnd('UI_SET_MAP_CUSTOM_INFO_WINDOW_MULTIPLE');
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}